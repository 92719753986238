import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Dropdown, Menu, Button, Icon } from 'antd';
import Form from 'components/Form';
import Table from 'components/Table';
import api from 'api';
import SearchSelect from 'components/Form/SearchSelect';
import bindUrl from 'lib/helpers/bindURL';
import './style.css';
import { userHasPrivileges } from 'lib/helpers/renderCondition';

const defaultProps = {
    editorActions: [],
    formActions: [],
    formTemplates: {},
    formTemplateSearch: {},
    size: 'middle',
}

function LinkedEditor(props) {

    const { user, initialLoadFromValues, editorActions, addExisting, refreshUrl, createUrl, createRPC, heading, tableProps, size, list, createPrivileges, deletePrivileges, deleteUrl, deleteLabel, values, dataTransform, dataUrl, injectedValues, reloadPageOnCreate, create, modalValuesTransform, instantCreate, instantCreateObject, primaryCreate, createLabel, formFields, formTemplates, formTemplateSearch, formActions } = props;

    const [editorModalAction, setEditorModalAction] = useState(null);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [creating, setCreating] = useState(false);
    const [deleteActive, setDeleteActive] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [errors, setErrors] = useState(null);
    const [submittingParameters, setSubmittingParameters] = useState(false);
    const [submission, setSubmission] = useState({});

    useEffect(() => {
        if(editorModalAction) {
            const { method } = editorModalAction;
            //onFormAction(actionInModal.method, submission);
            setSubmittingParameters(true);

            method({values, 
                parameters: submission, 
                loading: () => { setLoading(true); },
                success: () => { setLoading(false); },
                error: () => { setLoading(false); },
                reload: fetchData});
            setEditorModalAction(null);
        }

    }, [submission]);

    useEffect(() => {
        if(initialLoadFromValues) {
            let extract = dataTransform;
            if(typeof dataTransform === 'string') {
                extract = (data) => data[dataTransform]
            }
            const data = extract ? extract(values) : values;
            setData(data);
        } else {
            fetchData();
        }
    }, []);

    // async componentDidMount() {
    //     this.fetchData();
    // }

    const fetchData = async () => {
        setLoading(true);

        let extract = dataTransform;
        if(typeof dataTransform === 'string') {
            extract = (data) => data[dataTransform]
        }

        if(dataUrl){
            let boundUrl = bindUrl(dataUrl, values);
            let response = await api.get(boundUrl);

            if(response.status === 200){
                const data = extract ? extract(response.body) : response.body;
                setData(data);
                setLoading(false);

                if(data.length === 0) {
                    setDeleteActive(false);
                }
            } else {
                setData([]);
                setLoading(false);
                setDeleteActive(false);
            }

        } else {

            const data = extract ? extract(values) : values;
            setData(data);
            setLoading(false);

            if(data.length === 0) {
                setDeleteActive(false);
            }
        }

    }

    const addExistingButton = () => {

        if(!addExisting) {
            return null;
        } else {
            const { label, optionValue, optionContent, searchUrl, searchFields } = addExisting;

            return <SearchSelect addLabel={label} 
                                    onAdd={handleAddExisting}
                                searchUrl={searchUrl}
                             searchFields={searchFields}
                              optionValue={optionValue}
                            optionContent={optionContent} />
        }
    }

    const handleAddExisting = (selectedOptionValue) => {
        const { onAdd } = addExisting;

        if(onAdd){
            return onAdd(values, selectedOptionValue)
                    .then(result => {
                        fetchData();
                        return result;
                    });
        }
    }

    const handleEditorAction = (e,s) => {
        let action = editorActions[e.key];
        const { parametersForm, method } = action; 

        console.log("Acting ", action);
        if(parametersForm) {
            setEditorModalAction(action);
        } else {
            method({values, 
                loading: () => { setLoading(true); },
                success: () => { setLoading(false); },
                error: () => { setLoading(false); },
                reload: async (reloadPage = false) => {
                    if(reloadPage) {
                        setLoading(true)
                        window.location.reload();
                    } else { 
                        fetchData()
                    }
                }});
        }
    }

    const editorActionButtons = () => {

        if(editorActions.length === 0) {
            return null;
        }

        let menuNodes = editorActions.map((action, index) => {
            let icon = action.icon ? <Icon type={action.icon} /> : null;
            let disabled = false;
            //const disabled = action.validate && !action.validate({values}) ? true : false;
            let node = <Menu.Item disabled={disabled} key={index}>{icon}{action.label}</Menu.Item>

            // if(action.visible && (!values || !action.visible({values}))) {
            //     node = null;
            // }

            return node;
        }).filter(n => n);


        if(menuNodes.length) {
            let menu = <Menu key='editor_action_menu' onClick={handleEditorAction}>
                {menuNodes}
            </Menu>

            return <Dropdown overlay={menu}>
              <Button style={{marginRight: '12px'}}>
                Actions <Icon type="down" />
              </Button>
            </Dropdown>
        } else {
            return null;
        }
    }


    const createButton = () => {

        if(!createUrl && !createRPC) {
            return null;
        }

        if(instantCreate){
            const defaultObject = instantCreateObject ? instantCreateObject(values) : {};
            return <Button style={{marginRight: '12px'}} icon='plus' onClick={e => handleSubmit(defaultObject, false)}>{createLabel}</Button>
        } else {
            let formActions = [];
            if(create && create.formActions) {
                formActions = create.formActions;
            }

            let modalValues = modalValuesTransform ? modalValuesTransform(values) : null;
            return <Form values={modalValues} 
                modalIcon={'plus'}
                modalDisabled={deleteActive}
                modalPrimaryButton={primaryCreate} 
                modalButtonLabel={createLabel} 
                modalForm={true} 
                onSubmit={handleSubmit} 
                createMode={true} 
                fields={formFields} 
                formTemplates={formTemplates}
                formTemplateSearch={formTemplateSearch}
                formActions={formActions} />
        }
    }

    const deleteModeButton = () => {
        if(!deleteUrl) {
            return null;
        }

        let text;
        if(!deleteActive) {
            text = deleteLabel || 'Delete';
        } else {
            text = 'Finish Deleting';
        }

        return <Button icon='delete' style={{float: 'right'}} onClick={e => toggleDeleteMode()}>{text}</Button>
    }

    const collapseButton = () => {

        let icon = collapsed ? 'plus' : 'minus';

        return <Button icon={icon} style={{marginRight :'12px' }} onClick={e => toggleCollapse()} />
    }

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    }

    const toggleDeleteMode = () => {
        setDeleteActive(!deleteActive);
    }

    const createMethod = (data) => {

        if(typeof createUrl === 'function') {
            return api.create(createUrl(values), data);
        } else if(createUrl){
            return api.create(createUrl, data);
        } else if(createRPC){
            return api.rpc(createRPC, data);
        }

    }

    const handleDeleteRow = (record) => {
        return async (event) => {
            event.stopPropagation();

            let url;
            if(typeof deleteUrl === 'function') {
                url = deleteUrl(values);
            } else {
                url = deleteUrl;
            }

            url = bindUrl(url, record);
            setLoading(true);
            let result = await api.destroy(url);
            fetchData();
        }
    }

    const renderActionsParameterModal = () => {
        let visible = (editorModalAction !== null && typeof editorModalAction !== 'undefined');

        if(!visible) {
            return null;
        }

        return <Form 
                values={{}}
                showEditButtons={false}
                deleteEnabled={false}
                modalForm={true}
                showModalInstantly={true}
                modalSubmitText={editorModalAction.submitText}
                onSubmit={submitModalAction}
                onCancel={cancelModalAction}
                fields={editorModalAction.parametersForm} 
                />
    }

    const cancelModalAction = () => {
        setEditorModalAction(null);
    }

    const submitModalAction = (parameters) => {
        console.log("Submit ", parameters);
        setSubmission(parameters);
        return true;
    }

    const handleSubmit = (submittedValues, scrollBackToTop=true) => {
        let data = new FormData();
        let additionalValues = {};
        if(injectedValues) {
            additionalValues = injectedValues(values, user);
        }

        for(var key in submittedValues){
            data.append(key, submittedValues[key]);
        }

        for(var key in additionalValues){
            data.append(key, additionalValues[key]);
        }

        setErrors(null);
        setCreating(true);
        if(scrollBackToTop) {
            window.scrollTo(0, 0)
        }
        return createMethod(data)
        .then(response => { 
            if(response.errors){
                setCreating(false);
                setErrors(response.errors);
            } else {
                setCreating(false);
                if(reloadPageOnCreate) {
                    window.location.reload(); 
                } else {
                    fetchData();
                }
            }

            return response;
        })
        .catch(error => {
            setErrors(error);
            setCreating(false);
            throw error;
        });
    }

    const titleNode = () => <React.Fragment>
            {collapseButton()}
            <span id={`anchor_${heading}`} />
        {heading ? <p style={{fontSize: '16px', fontWeight: 'bold', margin: '0px 12px 0px 0px', display: 'inline-block'}}>{heading}{` (${data.length || '0'})`}</p> : null}
            {addExistingButton()}
            {showCreateButton ? createButton() : null}
            {editorActions.length ? editorActionButtons() : null}
            {showDeleteButton ? deleteModeButton() : null}
    </React.Fragment>


    let showCreateButton;
    if(!createPrivileges ||  (Array.isArray(createPrivileges) && createPrivileges.length === 0)) {
        //nothing to check for
        showCreateButton = true;
    } else {
        showCreateButton = userHasPrivileges(createPrivileges, user);
    }

    let showDeleteButton;
    if(!deletePrivileges || (Array.isArray(deletePrivileges) && deletePrivileges.length === 0)) {
        showDeleteButton = true;
    } else {
        showDeleteButton = userHasPrivileges(deletePrivileges, user);
    }


    let { columns, ...other} = tableProps;

    if(deleteActive) {
        let deleteColumn = {icon: 'close', onClick: handleDeleteRow, type: 'button', heading: 'Delete', cellWidth: '40px'};
        columns = columns.concat(deleteColumn);
    }

    return <React.Fragment>
        {renderActionsParameterModal()}
        <Table totalResults={data ? data.length : 0} 
                     titleNode={titleNode} 
                          size={list ? 'small' : size}
            showColumnHeadings={list ? false : true}
       hideSinglePagePagination={true}
                      bordered={true} 
                     collapsed={collapsed}
                       columns={columns}
                            {...other} 
                          data={data} 
                          user={user}
                       loading={loading || creating} /> 
            </React.Fragment>

}

LinkedEditor.defaultProps = defaultProps;

export default withRouter(LinkedEditor);
